<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <!--<query-item label="所属商户">-->
          <!--<a-select-->
            <!--allowClear-->
            <!--placeholder="请选择"-->
            <!--optionFilterProp="children"-->
            <!--v-model="query.storeId">-->
            <!--<a-select-option-->
              <!--v-for="(item) in storeList"-->
              <!--:key="item.code"-->
              <!--:value="item.code">-->
              <!--{{item.name}}-->
            <!--</a-select-option>-->
          <!--</a-select>-->
        <!--</query-item>-->
        <query-item label="门店名称">
          <a-input v-model.trim="query.name" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="联系人">
          <a-input v-model.trim="query.linkman" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="手机号">
          <a-input v-model.trim="query.mobile" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="门店状态">
          <a-select v-model="query.status" allowClear placeholder="请选择">
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { storeListAll, shopList, shopStatus } from '@/api/tool';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'ShopList',
  mixins: [queryMixin],
  components: {
  },
  props: {},
  data() {
    return {
      query: {
        storeId: '',
        name: '',
        linkman: '',
        mobile: '',
        stauts: '',

      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        // {
        //   title: '所属商户',
        //   dataIndex: 'storeName',
        //   key: 'storeName',
        //   width: 200,
        // },
        {
          title: '门店编号',
          dataIndex: 'shopId',
          key: 'shopId',
          width: 200,
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          width: 200,
        },

        {
          title: '联系人',
          dataIndex: 'linkman',
          key: 'linkman',
          width: 200,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 200,
        },
        {
          title: '管理员账号',
          dataIndex: 'loginName',
          key: 'loginName',
          width: 200,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row, index) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>
            );
          }
        }
      ],
      dataList: [],
      storeList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
    // this.getStoreData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      shopList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        this.dataList = list;
      });
    },

    // 商戶列表
    getStoreData() {
      storeListAll().then((data) => {
        this.storeList = data;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'shopAdd'
      });
    },

    // 查看
    handleInfo(row) {
      this.$router.push({
        name: 'shopInfo',
        params: {
          id: row.shopId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'shopEdit',
        params: {
          id: row.shopId
        }
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        shopId: row.shopId,
        status: row.status === 0 ? 1 : 0
      };

      shopStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
